import { AUTH_SUCCESS, AUTH_FAILURE, TOGGLE_LOADER, VALIDATE_OTP_SUCCESS, VALIDATE_OTP_FAILURE, USER_DATA_SUCCESS, USER_DATA_FAILURE, VALIDATE_PAN_SUCCESS, VALIDATE_PAN_FAILURE, REQUEST_AADHAAR_OTP_SUCCESS, REQUEST_AADHAAR_OTP_FAILURE, AADHAAR_VERIFICATION_SUCCESS, AADHAAR_VERIFICATION_FAILURE, BANK_VERIFICATION_SUCCESS, BANK_VERIFICATION_FAILURE } from "./types"
import Api from '../lib/api'

export const authenticate = (body) => async (dispatch) => {
  dispatch({
    type: TOGGLE_LOADER,
    payload: true
  })
  try {
    const response = await Api.post(body, '/auth')
    if (response.status === 'success') {

      dispatch({
        type: AUTH_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: AUTH_FAILURE,
        payload: response.data
      })

    }
    return response
  } catch (e) {
    dispatch({
      type: AUTH_FAILURE,
      payload: e.message
    })
  }
}

export const validateOtp = (otp) => async (dispatch) => {
  const body = otp
  try {
    const response = await Api.post(body, '/auth/otp')
    if (response.status === 'success') {
      dispatch({
        type: VALIDATE_OTP_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: VALIDATE_OTP_FAILURE,
        payload: response.data
      })
    }
    return response;
  } catch (e) {
    dispatch({
      type: VALIDATE_OTP_FAILURE,
      payload: e.message
    })
  }
}

export const getUserDetails = () => async (dispatch) => {
  try {
    const response = await Api.get('/hni/getUserData')
    if (response.status === 'success') {
      dispatch({
        type: USER_DATA_SUCCESS,
        payload: response.data
      })
      return response;
    } else {
      dispatch({
        type: USER_DATA_FAILURE,
        payload: response.data
      })
      return response;
    }
  } catch (e) {
    dispatch({
      type: USER_DATA_FAILURE,
      payload: e.message
    })
  }
}
