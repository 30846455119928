import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";

import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem, CardTitle } from "reactstrap";
import {
    Block,
    BlockDes,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Button,
    Row,
    Col,
    PreviewAltCard,
    TooltipComponent,
    DataTableRow,
    DataTableItem,
    DataTableHead,
} from "../components/Component";
import { BalanceBarChart, DepositBarChart, WithdrawBarChart } from "../components/partials/charts/invest/InvestChart";
import NewsUsers from "../components/partials/default/new-users/User";
import policies from "./pre-built/policies";
import { formatCurrency } from "../helpers/premium";
import { useSelector } from "react-redux";
import { path, pathOr } from "ramda";
import { useDispatch } from "react-redux";
import { getAllPolicies } from "../actions/policyAction";
import moment from "moment";

const Home = () => {
    const [recentUser, setRecentUser] = useState("");
    const [pol, setPolicies] = useState([]);
    const [sm, updateSm] = useState(false);
    const user = useSelector(state => state.Auth.user);
 const dispatch = useDispatch()
    const overAllPolicy = useSelector(state => state.Policy.policies);
    const policies = useSelector(state => state.Policy.policies)
      
  useEffect(() => {
      dispatch(getAllPolicies())
      setPolicies(overAllPolicy.sort((a, b) => {
    return (
      new Date(a.commencementDate) -
      new Date(b.commencementDate)
    );
    }).slice(-5))
  }, [])
    return (
        <React.Fragment>
            <Head title="Default Dashboard" />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>{pathOr('', ['firstName'], user)}{ "'s"} Dashboard</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>Welcome to OneView</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="more-v"></Icon>
                                </Button>
                                {/* <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                                    <ul className="nk-block-tools g-3">
                                        <li>
                                            <Button color="primary" outline className="btn-dim btn-white">
                                                <Icon name="download-cloud"></Icon>
                                                <span>Export</span>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button color="primary" outline className="btn-dim btn-white">
                                                <Icon name="reports"></Icon>
                                                <span>Reports</span>
                                            </Button>
                                        </li>
                                        <li className="nk-block-tools-opt">
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="transparent" className="dropdown-toggle btn btn-icon btn-primary">
                                                    <Icon name="plus"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <ul className="link-list-opt no-bdr">
                                                        <li>
                                                            <DropdownItem
                                                                href="#adduser"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                }}
                                                            >
                                                                <Icon name="user-add-fill"></Icon>
                                                                <span>Add User</span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li>
                                                            <DropdownItem
                                                                href="#addorder"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                }}
                                                            >
                                                                <Icon name="coin-alt-fill"></Icon>
                                                                <span>Add Order</span>
                                                            </DropdownItem>
                                                        </li>
                                                        <li>
                                                            <DropdownItem
                                                                href="#addpage"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                }}
                                                            >
                                                                <Icon name="note-add-fill-c"></Icon>
                                                                <span>Add Page</span>
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Row className="g-gs">

                        <Col md="2">
                            <PreviewAltCard className="card-full">
                                <div className="card-title-group align-start mb-0">
                                    <div className="card-title">
                                        <h6 className="subtitle">Number of policies</h6>
                                    </div>
                                    <div className="card-tools">
                                        <TooltipComponent
                                            iconClass="card-hint"
                                            icon="help-fill"
                                            direction="left"
                                            id="invest-deposit2"
                                            text="Number of insurance policies"
                                        ></TooltipComponent>
                                    </div>
                                </div>
                                <div className="card-amount">
                                    <span className="amount">
                                        {overAllPolicy.length}<span className="currency currency-usd"></span>
                                    </span>
                                </div>
                            </PreviewAltCard>
                        </Col>
                         <Col md="2">
                            <PreviewAltCard className="card-full">
                                <div className="card-title-group align-start mb-0">
                                    <div className="card-title">
                                        <h6 className="subtitle">General</h6>
                                    </div>
                                    <div className="card-tools">
                                        <TooltipComponent
                                            iconClass="card-hint"
                                            icon="help-fill"
                                            direction="left"
                                            id="invest-depositgf"
                                            text="Number of general insurance policies"
                                        ></TooltipComponent>
                                    </div>
                                </div>
                                <div className="card-amount">
                                    <span className="amount">
                                        {overAllPolicy.filter(p => p.insuranceType == 'General').length}<span className="currency currency-usd"></span>
                                    </span>
                                </div>
                            </PreviewAltCard>
                        </Col>
                         <Col md="2">
                            <PreviewAltCard className="card-full">
                                <div className="card-title-group align-start mb-0">
                                    <div className="card-title">
                                        <h6 className="subtitle">Health</h6>
                                    </div>
                                    <div className="card-tools">
                                        <TooltipComponent
                                            iconClass="card-hint"
                                            icon="help-fill"
                                            direction="left"
                                            id="invest-deposit21"
                                            text="Number of health insurance policies"
                                        ></TooltipComponent>
                                    </div>
                                </div>
                                <div className="card-amount">
                                    <span className="amount">
                                        {overAllPolicy.filter(p => p.insuranceType == 'Health').length}<span className="currency currency-usd"></span>
                                    </span>
                                </div>
                            </PreviewAltCard>
                        </Col>
                         <Col md="2">
                            <PreviewAltCard className="card-full">
                                <div className="card-title-group align-start mb-0">
                                    <div className="card-title">
                                        <h6 className="subtitle">Life</h6>
                                    </div>
                                    <div className="card-tools">
                                        <TooltipComponent
                                            iconClass="card-hint"
                                            icon="help-fill"
                                            direction="left"
                                            id="invest-deposit2"
                                            text="Number of life insurance policies"
                                        ></TooltipComponent>
                                    </div>
                                </div>
                                <div className="card-amount">
                                    <span className="amount">
                                        {overAllPolicy.filter(p => p.insuranceType == 'Life').length}<span className="currency currency-usd"></span>
                                    </span>
                                </div>
                            </PreviewAltCard>
                        </Col>
<Col md="6">
                        <Card className="card-bordered card-full">
                             <React.Fragment>
      <div className="card-inner border-bottom">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">Upcoming Policy Renewals</h6>
          </CardTitle>
          <div className="card-tools">
            <ul className="card-tools-nav">
              {/* <li className={recentUser === "Cancel" ? "active" : ""} onClick={() => setRecentUser("Cancel")}>
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>Cancel</span>
                </a>
              </li> */}
              {/* <li className={recentUser === "" ? "active" : ""} onClick={() => setRecentUser("")}>
                <a
                  href="/upcoming"
                  // onClick={(ev) => {
                  //   ev.preventDefault();

                  // }}
                >
                  <span>View All</span>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <Block>
                    <Card className="card-full">
                        <div className="nk-tb-list">
                            <DataTableHead>
                                <DataTableRow>
                                    <span style={{ color: '#000' }}>Insurer</span>{" "}
                                </DataTableRow>
                                <DataTableRow >
                                    <span style={{ color: '#000' }}>Policy Number</span>
                                </DataTableRow>
                                {/* <DataTableRow >
                                    <span style={{ color: '#000' }}>Premium</span>
                                </DataTableRow> */}
                                <DataTableRow >
                                    <span style={{ color: '#000' }}>Due Date</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span style={{ color: '#000' }}></span>
                                </DataTableRow>
                                
                            </DataTableHead>
                            {pol.map((item) => {
                                return (
                                    <DataTableItem key={item.id}>
                                        <DataTableRow>
                                             <div className="align-center">
                                                <span className="tb-sub">
                                                    <img src={item.insuranceName[0].logoThumb} width={50} height={50} />
                                                </span>
                                            </div>
                                        </DataTableRow>
                                        <DataTableRow >
                                        <div className="user-name">
                                            {item.policyNumber}
                                            </div>
                                        </DataTableRow>
                                        {/* <DataTableRow size="sm">
                                            <div className="user-card">

                                                <div className="user-name">
                                                    {'₹' +formatCurrency(item.premium)}
                                                </div>
                                            </div>
                                        </DataTableRow> */}
                                        <DataTableRow >
                                        <div className="user-name">
                                            {moment(item.commencementDate).add(item.term,'Year').format('DD-MM-YYYY')}
                                            </div>
                                    </DataTableRow>
                                    
                                        <DataTableRow>
                                          <a target="_blank" href={pathOr('',['insuranceName','0','paymentLink'],item)} class="btn btn-primary" rel="noreferrer">Pay Now</a>
                                        </DataTableRow>
                                    </DataTableItem>
                                );
                            })}
                        </div>
                    </Card>
                </Block>
    </React.Fragment>
                            </Card>
                            </Col>
                       
                        
{/* <Col xxl="12" md="12">
              <Card className="card-bordered card-full">
                <NewsUsers />
              </Card>
            </Col> */}
                       
                   
                    </Row>
                </Block>
            </Content>
        </React.Fragment>
    );
};

export default Home;
