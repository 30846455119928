import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./config/store";
import { PersistGate } from "redux-persist/integration/react";

const Error404Modern = lazy(() => import("./pages/error/404-modern"));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <Suspense fallback={<div />}>
      <Provider store={store} >
      <PersistGate persistor={persistor}>
      <Router basename={`/`}>
        <Route render={({ location }) => (location.state && location.state.is404 ? <Error404Modern /> : <App />)} />
      </Router>
      </PersistGate>
      </Provider>
    </Suspense>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
