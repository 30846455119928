const menu = [
  {
    text: "Home",
    link: "/home"
  },
  {
    text: "General",
    link: "/general"
  },
    {
    text: "Health",
    link: "/health"
  },
  {
    text: "Life",
    link: "/life"
  },
  // {
  //   text: "KYC",
  //   link: "/kyc"
  // },
  // {
  //   text: "POP (Pre owned policy)",
  //   link: "/pop"
  // }
];
export default menu;
