import React from "react";

import Error404Classic from "./pages/error/404-classic";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Success from "./pages/auth/Success";
import { Switch, Route, withRouter } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";
import OTP from "./pages/OTP";
import Home from "./pages/Home";
import PrivateRoute from "./route/PrivateRoute";
import Health from "./pages/Health";
import General from "./pages/General";
import Life from "./pages/Life";


const App = () => {
  return (
    <Switch>
      {/* Auth Pages */}
      <Route exact path={`${process.env.PUBLIC_URL}/auth-success`} component={Success}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/auth-reset`} component={ForgotPassword}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/otp`} component={OTP}></Route>
       {/*Main Routes*/}
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/home`} component={Home}></PrivateRoute>
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/health`} component={Health}></PrivateRoute>
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/general`} component={General}></PrivateRoute>
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/life`} component={Life}></PrivateRoute>
      {/*Error Pages*/}
      <Route exact path={`${process.env.PUBLIC_URL}/errors/404-classic`} component={Error404Classic}></Route>
      <Route component={RedirectAs404}></Route>
    </Switch>
  );
};
export default withRouter(App);
