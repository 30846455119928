export const frequencyMultiplier = (paymentFrequency:string) => {
    switch(paymentFrequency){
        case 'Monthly':
            return 12
          case 'Yearly':
            return 1
          case 'Quaterly':
            return 4
          case 'Half Yearly':
            return 2
          default:
            return 1
    }
}

export const formatCurrency = (val) => {
  if (val) {
    return new Intl.NumberFormat('en-IN', {currency: 'INR'}).format(val);
  } else {
    return 0;
  }
};