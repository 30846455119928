import React, { useEffect, useState } from "react";
import Logo from "../images/logo.png";
import LogoDark from "../images/logo-dark.png";
import PageContainer from "../layout/page-container/PageContainer";
import Head from "../layout/head/Head";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../components/Component";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import AuthFooter from "./auth/AuthFooter";
import { useDispatch, useSelector } from 'react-redux'
import { authenticate, getUserDetails, validateOtp } from "../actions/authAction";
import OtpInput from 'react-otp-input';
import './style.css'
import { pathOr } from 'ramda'

const OTP = (props) => {
  console.log(props)
  const dispatch = useDispatch()
  const location = useLocation();
  const { email, password } = props.location.state.formData
  const navigate = useHistory()
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = React.useState(30)
  const [minutes, setMinutes] = React.useState(0)
  const [errorVal, setError] = useState("");
  const [canResend, setCanResend] = React.useState(false)
  const name = useSelector(state => pathOr('', ['Auth', 'message', 'challengeName'], state))
  const session = useSelector(state => pathOr('', ['Auth', 'message', 'session'], state))

  const onFormSubmit = async (formData) => {
    setLoading(true);
    let status = false
    status = await dispatch(validateOtp({ otp }))
    if (status.status != "failure") {
      setError('')
      navigate.push('/home')
    } else {
      setError(status.data)
    }
    setLoading(false);
  };

  const { formState: { errors }, watch, register, handleSubmit } = useForm();
  useEffect(() => {

  })


  React.useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
        if (minutes === 0 && seconds === 0) {
          setCanResend(true)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  }, [canResend, seconds, minutes])

  const handleResend = async () => {

    setLoading(true);
    if (minutes === 0) {
      const status = await dispatch(authenticate({ email: email, password: password }))

      if (status.status === 'success') {
        setOtp('')
        setError('')
      } else {
        setError(status.data)
      }

      setCanResend(false)
      setSeconds(30)
    }

    setLoading(false);


  }
  const [otp, setOtp] = React.useState('')
  const otpError = useSelector(state => pathOr('', ['Auth', 'otpError'], state))

  const handleOtpOnPress = async (val) => {
    let status = false
    status = await dispatch(validateOtp({ otp: val, name, session }))
    if (status.status != "failure") {
      dispatch(getUserDetails())
      setError('')
      navigate.push('/home')
    } else {
      setError(status.data)
    }
  }

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Verification Code</BlockTitle>
                <BlockDes>
                  <p>We have sent an Verification Code for your registered mobile number.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> Invalid Verification Code{" "}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-control-wrap">
                  <OtpInput
                    value={otp}
                    onChange={(val) => {
                      setOtp(val)
                      if (val.length === 6) {
                        handleOtpOnPress(val)
                      }
                    }}
                    numInputs={6}
                    containerStyle={"otpContainer"}
                    hasErrored={!!otpError}
                    inputStyle={{
                      width: '2rem',
                      height: '2rem',
                      margin: '0 1rem',
                      fontSize: '2rem',
                      borderRadius: '4px',
                      border: '1px solid rgba(0,0,0,0.3)',
                      marginTop: '10px',
                      marginBottom: '10px'
                    }}
                    isInputNum={true}
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" type="submit" color="primary">
                  {loading ? <Spinner size="sm" color="light" /> : "Verify"}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 text-center pt-2">
              {" "}
              {seconds === 0 && (
                <Link onClick={() => { handleResend() }}>
                  {seconds === 0 ? 'Resend OTP' : 'Resend OTP (' + seconds + 's)'}
                </Link>
              )}
              {seconds !== 0 && (
                <p> {seconds === 0 ? 'Resend OTP' : 'Resend OTP (' + seconds + 's)'}</p>
              )}

            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default OTP;
