import Api from "../lib/api"
import { GET_ALL_POLICIES_FAILURE, TOGGLE_LOADER, GET_ALL_POLICIES_SUCCESS,GET_ALL_PAYMENTS_SUCCESS,GET_ALL_PAYMENTS_FAILURE, UPDATE_PAYMENT_STATUS_SUCCESS, UPDATE_PAYMENT_STATUS_FAILURE,GET_POLICY_DETAILS_SUCCESS,GET_POLICY_DETAILS_FAILURE, GET_ALL_PAYMENTS_PERPOLICY_SUCCESS, GET_ALL_PAYMENTS_PERPOLICY_FAILURE } from "./types"

export const getAllPolicies = () => async (dispatch) => {
    dispatch({
      type: TOGGLE_LOADER,
      payload: true
    })
    try {
      const response = await Api.get('/policy/customer')
      if (response.status === 'success') {
  
        dispatch({
          type: GET_ALL_POLICIES_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: GET_ALL_POLICIES_FAILURE,
          payload: response.data
        })
  
      }
      return response
    } catch (e) {
      dispatch({
        type: GET_ALL_POLICIES_FAILURE,
        payload: e.message
      })
    }
  }
  
export const getPolicyDetails = body => async (dispatch) => {
    dispatch({
      type: TOGGLE_LOADER,
      payload: true
    })
    try {
      const response = await Api.post(body, '/hni/getpolicyDetails')
      if (response.status === 'success') {
  
        dispatch({
          type: GET_POLICY_DETAILS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: GET_POLICY_DETAILS_FAILURE,
          payload: response.data
        })
  
      }
      return response
    } catch (e) {
      dispatch({
        type: GET_POLICY_DETAILS_FAILURE,
        payload: e.message
      })
    }
}
  

  