import { AUTH_SUCCESS, AUTH_FAILURE, VALIDATE_OTP_SUCCESS, USER_DATA_SUCCESS, LOGOUT, UPDATE_AUTH_TOKEN } from "../actions/types"

const initialState = {
    message: '',
    session: '',
    AccessToken: '',
    user: {},
    currency: 'INR',
    currencyRate: {},
    requestId: '',
}

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SUCCESS:
            return Object.assign({}, state, {
                message: action.payload
            })
        case AUTH_FAILURE:
            return Object.assign({}, state, {
                message: action.payload
            })
        case USER_DATA_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload
            })
        case VALIDATE_OTP_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload
            })
        case UPDATE_AUTH_TOKEN:
            return Object.assign({}, state, {
                AccessToken: action.payload.accessToken
            }) 
        case LOGOUT:
            return Object.assign({}, state, {
                message: '',
                session: '',
                AccessToken: '',
                RefreshToken: '',
                user: {},
                currency: 'INR',
                currencyRate: {}
            })
        default:
            return state
    }
}

export default AuthReducer;
