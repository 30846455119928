import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";

import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
    Block,
    BlockDes,
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Button,
    Row,
    Col,
    PreviewAltCard,
    TooltipComponent,
    DataTableRow,
    DataTableItem,
    DataTableHead,
} from "../components/Component";
import { BalanceBarChart, DepositBarChart, WithdrawBarChart } from "../components/partials/charts/invest/InvestChart";
import NewsUsers from "../components/partials/default/new-users/User";
import policies from "./pre-built/policies";
import { formatCurrency } from "../helpers/premium";
import { useSelector } from "react-redux";
import { pathOr } from "ramda";
import { useDispatch } from "react-redux";
import { getAllPolicies } from "../actions/policyAction";
import moment from "moment";

const Health = () => {
    const [sm, updateSm] = useState(false);
    const user = useSelector(state => state.Auth.user);
    const dispatch = useDispatch();
    const policies = useSelector(state => state.Policy.policies.filter(p => p.insuranceType == 'Health'));
    const [data, setData] = useState(policies)
  useEffect(() => {
    dispatch(getAllPolicies())
  }, [])
    return (
        <React.Fragment>
            <Head title="Default Dashboard" />
            <Content>
                <BlockHead >
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Health Insurance Policy</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>Policy Data</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button
                                    className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                                    onClick={() => updateSm(!sm)}
                                >
                                    <Icon name="more-v"></Icon>
                                </Button>
                                
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Row className="g-gs">
                   
                        <Col md="2">
                            <PreviewAltCard className="card-full">
                                <div className="card-title-group align-start mb-0">
                                    <div className="card-title">
                                        <h6 className="subtitle">Number of policies</h6>
                                    </div>
                                    <div className="card-tools">
                                        <TooltipComponent
                                            iconClass="card-hint"
                                            icon="help-fill"
                                            direction="left"
                                            id="invest-deposit2"
                                            text="Number of policies invested"
                                        ></TooltipComponent>
                                    </div>
                                </div>
                                <div className="card-amount">
                                    <span className="amount">
                                        {policies.length}<span className="currency currency-usd"></span>
                                    </span>
                                </div>
                            </PreviewAltCard>
                        </Col>
                       
                        
{/* <Col xxl="12" md="12">
              <Card className="card-bordered card-full">
                <NewsUsers />
              </Card>
            </Col> */}
                       
                   
                    </Row>
                </Block>

 <Block>
                    <Card className="card-bordered card-full">
                        <div className="nk-tb-list">
                            <DataTableHead>
                                <DataTableRow>
                                    <span style={{ color: '#000' }}>Insurer</span>{" "}
                                </DataTableRow>
                                <DataTableRow >
                                    <span style={{ color: '#000' }}>Policy Number</span>
                                </DataTableRow>
                                <DataTableRow >
                                    <span style={{ color: '#000' }}>Policyholder</span>
                                </DataTableRow>
                                <DataTableRow >
                                    <span style={{ color: '#000' }}>Sum Assured</span>
                                </DataTableRow>
                                <DataTableRow >
                                    <span style={{ color: '#000' }}>Commencement Date</span>
                                </DataTableRow>
                                <DataTableRow >
                                    <span style={{ color: '#000' }}>Due Date</span>
                                </DataTableRow>
                                <DataTableRow >
                                    <span style={{ color: '#000' }}></span>
                                </DataTableRow>
                                
                            </DataTableHead>
                            {data.map((item) => {
                                return (
                                    <DataTableItem key={item.id}>
                                        <DataTableRow>
                                            <div className="align-center">
                                                <span className="tb-sub ml-2">
                                                    <img src={item.insuranceName[0].logoThumb} width={50} height={50} />
                                                </span>
                                            </div>
                                        </DataTableRow>
                                        <DataTableRow >
                                            <a
                            href={'/policydetails/'+item._id}
                           
                            className="project-title"
                          >
                                        <div className="user-name">
                                            {item.policyNumber}
                                                </div>
                                                </a>
                                        </DataTableRow>
                                        <DataTableRow >
                                        <div className="user-name">
                                            {item.policyHolderName}
                                            </div>
                                        </DataTableRow>
                                        <DataTableRow >
                                            <div className="user-card">

                                                <div className="user-name">
                                                    {'₹ ' +formatCurrency(item.sumAssured)}
                                                </div>
                                            </div>
                                        </DataTableRow>
                                        <DataTableRow >
                                        <div className="user-name">
                                            {moment(item.commencementDate).format('DD-MM-YYYY')}
                                            </div>
                                        </DataTableRow>
                                          <DataTableRow >
                                            <div className="user-card">
                                                <div className="user-name">
                                                    {moment(item.commencementDate).add(item.term,'Year').format('DD-MM-YYYY')}
                                                </div>
                                            </div>
                                        </DataTableRow>
                                        <DataTableRow >
                                            {/* <a href={'/policydetails/' + item._id} class="btn btn-sm btn-primary">View</a> */}
                                              <a 
                                  href="#download"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    window.open (pathOr('',['policyUrl'],item),'_blank')
                                  }}
                                  className="popup btn btn-sm btn-primary"
                                >View
                                  <Icon name="download" style={{"margin-left":"10px"}}></Icon>
                                </a>
                          {/* <ul className="list-inline list-download">
                            
                              <li>
                              {" "}
                                <a
                                  href="#download"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    window.open (pathOr('',['assignment','signedDocument'],item),'_blank')
                                  }}
                                  className="popup"
                                >
                                  <Icon name="download"></Icon>
                                </a>
                              </li>
                            
                          </ul> */}
                        </DataTableRow>
                                    </DataTableItem>
                                );
                            })}
                        </div>
                    </Card>
                </Block>

            </Content>
        </React.Fragment>
    );
};

export default Health;
