export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const LOGOUT='LOGOUT'
export const TOGGLE_LOADER='TOGGLE_LOADER'
export const UPDATE_AUTH_TOKEN='UPDATE_AUTH_TOKEN'
export const VALIDATE_OTP_SUCCESS='VALIDATE_OTP_SUCCESS'
export const VALIDATE_OTP_FAILURE='VALIDATE_OTP_FAILURE'
export const USER_DATA_SUCCESS='USER_DATA_SUCCESS'
export const USER_DATA_FAILURE='USER_DATA_FAILURE'
export const GET_ALL_POLICIES_SUCCESS='GET_ALL_POLICIES_SUCCESS'
export const GET_ALL_POLICIES_FAILURE='GET_ALL_POLICIES_FAILURE'
export const GET_POLICY_DETAILS_SUCCESS='GET_POLICY_DETAILS_SUCCESS'
export const GET_POLICY_DETAILS_FAILURE = 'GET_POLICY_DETAILS_FAILURE'
