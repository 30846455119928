import { GET_ALL_POLICIES_SUCCESS,GET_POLICY_DETAILS_SUCCESS } from "../actions/types"

const initialState = {
    policies: [],
    paymentHistory: [],
    paymentHistoryPerPolicy: [],
    policyDetails:{},
}

const PolicyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_POLICIES_SUCCESS:
            return Object.assign({}, state, {
                policies: action.payload
            })
        case GET_POLICY_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                policyDetails: action.payload
            })
        default:
            return state
    }
}

export default PolicyReducer;
