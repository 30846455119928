import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Layout from "../layout/Index";
const PrivateRoute = ({ exact, component: Component, ...rest }) => {
  const auth = useSelector(state => state.Auth.AccessToken);
  return (
    <Route
      exact={exact ? true : false}
      rest
      render={(props) =>
        auth ? (
          <Route exact
            {...rest} render={() => (
              <Layout>
                <Component {...props} {...rest}></Component>
              </Layout>
            )}></Route>
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/login`}></Redirect>
        )
      }
    ></Route>
  )
};

export default PrivateRoute;
