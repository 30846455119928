export const newUserData = [
  {
    initial: "PR",
    theme: "purple-dim",
    name: "Pavan Ranga",
    email: "info@softnio.com",
    amount: "₹ 2,00,00,000",
    noofpolicies: "2",
    invested: "₹ 1,00,00,000",
    policyValue: "₹ 1,20,00,000",
    expectedroi: "12.4%",
    upcomingRenewal: "₹ 50,00,000",
    nominee: "Arjun Ranga",
    id:1,
  },
  {
    initial: "SD",
    theme: "danger-dim",
    name: "Sudhanva Dhananjaya",
    email: "sharon-90@example.com",
    amount: "₹ 2,50,00,000",
    noofpolicies: "2",
    invested: "₹ 1,00,00,000",
    policyValue: "₹ 1,20,00,000",
    expectedroi: "12.4%",
    upcomingRenewal: "₹ 50,00,000",
    nominee: "Lajwanthi Sudhanva",
    id:2,
  },
  {
    initial: "UJ",
    theme: "warning-dim",
    name: "Umed Raj Jain",
    email: "gloria_72@example.com",
    amount: "₹ 50,00,000",
    noofpolicies: "2",
    invested: "₹ 1,00,00,000",
    policyValue: "₹ 1,20,00,000",
    expectedroi: "12.4%",
    upcomingRenewal: "₹ 50,00,000",
    nominee: "Rekha U Jain",
    id:3,
  },
  {
    initial: "VK",
    theme: "success-dim",
    name: "Vikash",
    email: "phillip-85@example.com",
    amount: "₹ 1,00,00,000",
    noofpolicies: "2",
    invested: "₹ 1,00,00,000",
    policyValue: "₹ 1,20,00,000",
    expectedroi: "12.4%",
    upcomingRenewal: "₹ 50,00,000",
    nominee: "Jaya",
    id:4,
  },
];
